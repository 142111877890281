import { useNavigate } from "react-router-dom"
import { useApi } from "../../context/ApiContext"
import { useAuth } from "../../context/AuthContext"
import { useCommonPopup } from "../../context/CommonPopupContext"
import UserName from "../inc/UserName"
import NotificationPopup from "./popup/NotificationPopup"
import { useRef, useState, useEffect} from "react"

const DashBoardHeader = () => {

  const navigate = useNavigate()
  const auth = useAuth()
  const api = useApi()
  const {confirm} = useCommonPopup()
  const timerRef = useRef()
  const [newNoti, setNewNoti] = useState(0)
  const [showNotification, setShowNotification] = useState(false)

  useEffect(_ => {

    timerRef.current = setInterval(() => {
      getNewNoti()
    }, 1000)

    return ()=>{ 
      clearInterval(timerRef.current)
    }
  }, [])

  const onClickAdmin = () => {

    navigate("/admin")
  }

  const onClickNotification = () => {
    setShowNotification(true)
  }

  const onClosedNotification = () => {
    setShowNotification(false)
  }
  
  /*=========================================================
   *  Data 
   =========================================================*/
   const getNewNoti = async () => {

    const result = await api.post("getNewNoti", {
    })    

    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
    } else {
      setNewNoti(result.data.data.new)
    }
  }
  
  return (
    <header>            
      <div className="dash_head">
        <div className="inwrap">
          <div className="head_profile">
            <h1><a><i className="icon-logo"></i></a></h1>
            <div>
              {(auth.adminInfo?.level === 1 || auth.adminInfo?.level === 2) && <span className="admin_center" onClick={onClickAdmin}>관리자 센터</span>}
              <span className="alarm">
                {newNoti > 0 && <em></em>}
                <i className="icon-bell" onClick={onClickNotification}></i>  
                {showNotification && <NotificationPopup onClosed={() => {onClosedNotification()}}/> }         
              </span>
              <UserName/>
            </div>
          </div>
          <div className="head_info">
            <h4>{auth.adminInfo?.name}님, 반가워요. 👋 </h4>
            <p>리서치플로우에 오신 것을 환영합니다. 프로젝트를 만들어서 연구자들과 공유하고 설문을 손쉽게 관리해보세요.</p>
          </div>
        </div>
      </div>
    </header>
  )
}  
export default DashBoardHeader