import { useEffect, useRef, useState } from "react"
import { SURVEY_QUESTION_TYPE, 
  SURVEY_QUESTION_TYPE_YESORNO,
  SURVEY_QUESTION_TYPE_MULTIPLECHOICE,
  SURVEY_QUESTION_TYPE_SCORE,
  SURVEY_QUESTION_TYPE_ESSAY,
  SURVEY_QUESTION_TYPE_DATE }
   from "../../common/codeHelper"
import { useCommonPopup } from "../../context/CommonPopupContext"
import { useApi } from "../../context/ApiContext"
import { questionImgageUrl } from "../../common/imageHelper"
import { validatePhoneNumberWithHiphen } from "../../common/stringHelper"

const PreviewQuestion = (props) => {

  const {alert} = useCommonPopup()
  const {accessToken, onPrev, onNext} = props
  const api = useApi()

  const [answer, setAnswer] = useState("")
  const [reason, setReason] = useState("")
  var oldAnswer = useRef("")
  var oldreason = useRef("")
  var timer = useRef()

  const [currentData, setCurrentData] = useState()
  const imageRef = useRef()
  const [imageHeight, setImageHeight] = useState(0)
  const [checkOK, setCheckOK] = useState(false)

  useEffect(_ => {
    
    return ()=>{ 
      clearTimeout(timer)
    }

  }, [])

  useEffect(() => {

    if (!imageRef.current) return
    setImageHeight(5 / 4 * imageRef.current.offsetWidth)

  }, [imageRef.current])

  useEffect(_ => {
   
    if (currentData?.question?.idquestion != props.currentData?.question?.idquestion) {
      setCurrentData(props.currentData)
    }
    
  }, [props])

  useEffect(_ => {
       
    if (!!currentData?.question) {

      oldAnswer.current = currentData.question.answercontent
      oldreason.current = currentData.question.answerreason

      setAnswer("")
      setReason("")
      setCheckOK(currentData.question.mandatory !== 1)

      if (imageRef.current != null) {
        setImageHeight(5 / 4 * imageRef.current.offsetWidth)
      }
    }
  }, [currentData])

  useEffect(_ => {
       
    if (oldAnswer.current !== answer) {      
      oldAnswer.current = answer
    }
    setCheckOK(checkValidation())
  }, [answer])

  useEffect(_ => {
       
    if (oldreason.current !== reason) {     
      oldreason.current = reason
    }  
    setCheckOK(checkValidation())

  }, [reason])


  const checkValidation = () => {

    if (!currentData?.question) return false

    console.log(`checkValidation mandatory = ${currentData.question.mandatory}`)

    if (currentData.question.mandatory !== 1) return true

    const items = currentData.question.items.filter((element) => isSelected(element))
    console.log(`checkValidation items = ${JSON.stringify(items)}`)

    const etcs = items.filter((element) => element.isetc === 1)
    console.log(`checkValidation etcs = ${JSON.stringify(etcs)}`)
    if (etcs?.length > 0) {
      console.log(`selected items ${JSON.stringify(etcs)}`)
      return reason?.length > 0
    } 
    if (currentData.question.isconditional === 1 && currentData.question.conditiontype === 'phoneno') {
      return validatePhoneNumberWithHiphen(answer)
    }
    return !!answer
  }

  const updateAnswer = (data, timeout) => {    
    setAnswer(data)
  }

  const updateReason = (data, timeout) => {
  
    setReason(data)
  }

  const onToggleAnswer = (item, value) => {
      
    if (currentData.question.multipleselect === 1) {
      var list = !!answer ? answer.split(",") : []
      var ret = list.filter((element) => Number(element) !== Number(item.orderno))
      if (value === true) ret.push(Number(item.orderno))      
      var data = ret.join(",")
      updateAnswer(data, 0)
      
    } else {
      var data = `${item.orderno}`
      updateAnswer(data, 0)
    }
  }

  const onSelectAnswer = (item) => {
      
    if (currentData.question.multipleselect === 1) {
      var list = !!answer ? answer.split(",") : []
      var ret = list.filter((element) => Number(element) === Number(item.orderno))
      if (ret?.length == 0) list.push(Number(item.orderno))      
      var data = list.join(",")
      updateAnswer(data, 0)
      
    } else {
      var data = `${item.orderno}`
      updateAnswer(data, 0)
    }
  }


  const onInputAnswer = (e) => {

    var value = e.target.value
    if (value.length > 300) return
    updateAnswer(value)

    e.target.style.height = 0;
    e.target.style.height = 2 + e.target.scrollHeight  + 'px';
  }

  const onInputReason = (e) => {

    var value = e.target.value
    if (value.length > 300) return
    updateReason(value)    

    e.target.style.height = 0;
    e.target.style.height = 2 + e.target.scrollHeight  + 'px';
  }

  const onChangeDate = (e) => {

    var value = e.target.value
    updateAnswer(value)    
  }

  const isSelected = (item) => {
    
    if (currentData.question.multipleselect === 1) {
      const list = !!answer ? answer.split(",") : []       
      var ret = list.filter((element) => Number(element) === Number(item.orderno))
      return (ret?.length > 0) 
    } else {
      return answer === `${item.orderno}`
    }
  }

  const onClickPrev = () => {

    onPrev()
  }

  const onClickNext = () => {

    onNext(answer)
  }


  const YesOrNoContent = () => {

    return (
      <ul className='radio checkbox'>
        { currentData.question.items.map((item, index) => 
            <li key={`question-${index}`}>
              <label>
                <input 
                  type="radio" 
                  name="question" 
                  checked={isSelected(item)} 
                  onChange={(e) => { onSelectAnswer(item) }}
                /><span>{item.content}</span>
              </label>
              { currentData.question.inputreason === 1 && isSelected(item) && 
                <>
                  <textarea 
                    className={`full solo ${answer?.length === 300 ? 'danger' : ''}`} 
                    id="textarea_auto" 
                    placeholder="답변 입력" 
                    rows="1" 
                    value={reason}
                    onChange={onInputReason}> 
                  </textarea>
                  <p className='text_length'>{reason?.length}/300</p>
                </>
              }
            </li>
          )
        }
      </ul>
    )
  }

  const ChoiceContent = () => {

    return (
      <ul className='radio checkbox'>
        { currentData.question.items.map((item, index) => 
            <li key={`question-${index}`}>
              {currentData.question.multipleselect === 1 ? (
                <label>
                  <input type="checkbox" 
                    name="checkbox" 
                    checked={isSelected(item)} 
                    onChange={(e) => { onToggleAnswer(item, e.target.checked) }}/>
                  <span>{item.content}</span>
                  {(item.isetc && isSelected(item)) ?
                    <>
                      :
                      <textarea className={`solo etc ${answer?.length === 300 ? 'danger' : ''}`}
                        id="textarea_auto" 
                        placeholder="답변 입력" 
                        rows="1"
                        value={reason}
                        onChange={onInputReason}
                      /> 
                    </>: null
                  }
                </label>
              ) : (
                <label>
                  <input 
                    type="radio" 
                    name="question" 
                    checked={isSelected(item)} 
                    onChange={(e) => { onSelectAnswer(item) }}/>
                  <span>{item.content}</span>
                  {(item.isetc && isSelected(item)) ? 
                    <>
                      :
                      <textarea className={`solo etc ${answer?.length === 300 ? 'danger' : ''}`}
                        id="textarea_auto" 
                        placeholder="답변 입력" 
                        rows="1"
                        value={reason}
                        onChange={onInputReason}
                      /> 
                    </>: null
                  }
                </label>
                
              )}
              {/* { currentData.question.inputreason === 1 && item.selected === true && 
                <>
                  <textarea className="full solo danger" id="textarea_auto" placeholder="답변 입력" rows="1"> </textarea>
                  <p className='text_length'>0/300</p>
                </>
              } */}
            </li>
          )
        }
      </ul>
    )
  }

  const ScoreContent = () => {

    return (
      <>
        <ul className={`score count_${currentData.question.scale}`}>
          { currentData.question.items.map((item, index) => 
              <li key={`question-${index}`}
                className={`tooltip ${isSelected(item) ? "active" : ""}`} 
                onClick={() => onSelectAnswer(item)}
              >{item.score}
              {item.content?.length > 0 && <span className="tooltiptext tooltip-top">{item.content}</span>}
              </li>
            )
          }
        </ul>
        <div> 
          {currentData.question.items?.length > 0 && currentData.question.items[0].score}점 : {currentData.question.items[0].content}&nbsp;-&nbsp; 
          {currentData.question.items?.length > 0 && currentData.question.items[currentData.question.items.length - 1].score}점 : {currentData.question.items[currentData.question.items.length - 1].content}
        </div>
      </>
    )
  }

  const essayContent = () => {
    return (
      <>
        <ul className='radio'>
          <li>
            <textarea 
              className={`full solo ${answer?.length === 300}`} 
              id="textarea_auto" 
              placeholder={currentData.question.isconditional === 1 && currentData.question.conditiontype === 'phoneno' ? '휴대폰 번호를 입력해주세요 (- 없이 숫자만 입력)' : "답변 입력"}
              rows="1"
              value={answer}
              onChange={onInputAnswer}
            /> 
            <p className='text_length'>{answer?.length}/300</p>
          </li>
        </ul>
      </>
    )
  }

  const dateContent = () => {
    return (
      <>
        <input className={`big ${answer?.length > 0 ? "active" : ""}`} type="date" value={answer} onChange={onChangeDate}/>
      </>
    )
  }
  
  return (
    !!currentData ? (
      <div className="inwrap">
        <div className='questionnair_body'>
          { currentData.section.commonquestion?.length > 0 &&
            <p className='bg_gray'>
              {currentData.section.commonquestion}
            </p>
          }
          <div className='bg_white'>
          <h3>{currentData.question.orderno}. {currentData.question.content}</h3>
          { currentData.question?.image?.length > 0 && <img 
              ref={imageRef}
              style={{maxHeight:`${imageHeight}px`, objectFit: "cover"}}
              src={questionImgageUrl(currentData.question.image, "large")}
            />
          }
          { currentData.question?.type === SURVEY_QUESTION_TYPE_YESORNO && YesOrNoContent() }
          { currentData.question?.type === SURVEY_QUESTION_TYPE_MULTIPLECHOICE && ChoiceContent() }
          { currentData.question?.type === SURVEY_QUESTION_TYPE_SCORE && ScoreContent() }
          { currentData.question?.type === SURVEY_QUESTION_TYPE_ESSAY && essayContent()}
          { currentData.question?.type === SURVEY_QUESTION_TYPE_DATE && dateContent()} 
          </div>
          <div className="but_wrap">
            { currentData.hasprev ? (
                <>
                  <button type="button" className="secondary gray large " onClick={onClickPrev}>이전</button>
                  <button type="button" 
                    className={`primary large ${!checkOK ? "disabled" : ""}`}
                    disabled={!checkOK}
                    onClick={onClickNext}>
                      다음
                  </button>
                </>
              ) : (
                <button type="button" className="primary large full" onClick={onClickNext}>시작</button>
              )
            }
          </div>
        </div> 
      </div>
    ) : (null)
  )
}
export default PreviewQuestion