import React, { createContext, useEffect, useState, useMemo, useContext, useRef } from 'react'
import axios from 'axios'
import { useAuth } from './AuthContext'
import { setCookie, getCookie, removeCookie } from '../common/cookieHelper'
import { useNavigate } from 'react-router-dom'

export const accessTokenCookieKey = '__RESEARCHFLOW_ACCESS_TOKEN'
export const autoSigninCookieKey = '__RESEARCHFLOW_AUTO_SIGNIN'

export var ApiContext = createContext()

export function ApiContextProvider({ children }) {
  
  const navigate = useNavigate()  
  const instance = useRef(axios.create({
    baseURL: process.env.REACT_APP_API_URL, 
    headers: {
        "Content-Type": "application/json",
        // withCredentials: true,
    },
    timeout: 30000,
  }))
  var isInit = useRef(false)

  useEffect(() => {
    
    // init()

  }, [])

  const apiClient = () => {    
    
    if (isInit.current) return instance.current

    instance.current.interceptors.request.use((config) => {     
                
        const accessToken = getAccessToken()
        if (accessToken && accessToken !== 'undefined') {
            config.headers['Authorization'] = `Bearer ${accessToken}`
        }
        return config
    }, error => {        
        Promise.reject(error)
    })
    
    instance.current.interceptors.response.use(response => {
      
      print("NETWORK", response.config.baseURL, response.config.data, response.data)      
      return response
    })
    isInit.current = true
    return instance.current
  }

  const setAccessToken = token => {    
    setCookie(accessTokenCookieKey, token)
  }

  const getAccessToken = () => {        
    const token = getCookie(accessTokenCookieKey)
    return token
  }

  const removeAuthToken = () => {
    removeCookie(accessTokenCookieKey)
  }

  const setAutoSignin = autoSignin => {    
    setCookie(autoSigninCookieKey, autoSignin ? "true" : "false")
  }

  const getAutoSignin = () => {
    return getCookie(autoSigninCookieKey) == "true"
  }

  // const get = async (url, slug = {}) => {

  //   const params = getQueryStringFromObject(slug)
  //   const instance = apiClient()
  //   return instance
  //       .get(`${url}${params}`)
  //       .then(({ status, data }) => {
  //           if (status === 200) {
  //               print('get', url, slug, data.data)                
  //               return data.data
  //           }
  //       })
  //       .catch(errorHandler)
  // }

  const post = async (action, params, useAuth = true) => {

    params["action"] = action

    const instance = apiClient()
    return instance
        .post('', params)
        .then(({ status, data }) => {
            return { status, data }
        })
        .catch(error => {
          print("ERROR", error.config.baseURL, error.config.data, error)
          return { error }
          // return errorHandler(error)
        })
  }

  const getQueryStringFromObject = (slug = {}) => {

    return '?' + Object.keys(slug).filter(key => (slug[key] != null && slug[key] !== 'null'))
        .map(key => key + '=' + slug[key]).join('&')
  }

  const errorHandler = (data) => {
    console.log(`errorHandler ${JSON.stringify(data)}`)
    return data
  }

  const print = (tag, url, param, data) => {
 
    var printParam = JSON.parse(param)
    if (printParam.action === "uploadProfileImage") {
      printParam.file = "image"
    }
    var printData = JSON.parse(JSON.stringify(data))
    if (printParam.action === "downloadQuestionImage" || printParam.action === "downloadParticipantAgreement") {
      if (!!printData.data) printData.data["image"] = "image"
    } 
    
    console.log(`===========================================
${tag} : ${url}
param : ${JSON.stringify(printParam)}
-------------------------------------------
recv : ${JSON.stringify(printData)}}
===========================================`)
  }

  const networkError = async (result, alert) => {
    
    if (result?.data?.code === 50) {
      await showAlert("세션이 종료되었습니다.\n다시 로그인 해주세요.", alert)      
      navigate("/signin")
    } else if (result?.data?.code === 60) {
      await showAlert(result?.data?.msg?? "접근 권한이 없습니다.\n다시 로그인 해주세요.", alert)      
      navigate("/signin")
    } else if (!!result?.data?.msg ){
      await showAlert(result?.data?.msg, alert)      
    } else if (!!result?.error?.message) {
      if (result.error.message.includes("timeout")) {
        await showAlert("시간이 초과되었습니다.\n다시 시도해 주세요.", alert)    
      } else {
        await showAlert(result?.error?.message, alert)    
      }
    } else {
      await showAlert(result?.data?.msg ?? "error", alert)      
    }
  }

  return (
    <ApiContext.Provider
      value={{post, getAccessToken, setAccessToken, removeAuthToken, setAutoSignin, getAutoSignin, networkError}}
    >
      {children}
    </ApiContext.Provider>
  )
}

const showAlert = async (message, alert) => {
  if (!!alert) {
    return alert(message, {title: "오류"})
  } else {
    window.alert(message)
  }
}
  
export function useApi() {
  return useContext(ApiContext)
}