import $ from 'jquery';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSurvey } from "../../context/SurveyContext";
import { useAuth } from "../../context/AuthContext";
import SurveyScaleSourcePopup from "./popup/SurveyScaleSourcePopup";
import { useEffect, useRef, useState } from "react";
import { initPopupMenu } from "../../common/javascriptHelper";
import { useCommonPopup } from "../../context/CommonPopupContext";
import { useApi } from "../../context/ApiContext";
import { SURVEY_STATUS_CLOSED, SURVEY_STATUS_OPEN, SURVEY_STATUS_WRITING } from "../../common/codeHelper";
import { useProject } from "../../context/ProjectContext";
import UserName from "../inc/UserName";
import SurveyExportPopup from "./popup/SurveyExportPopup";
import queryString from "query-string";
import SurveyLinkPopup from "./popup/SurveyLinkPopup";

const SurveyHeader = (props) => {

  const { tab } = useParams()
  const navigate = useNavigate()
  const location = useLocation();
  const auth = useAuth()  
  const api = useApi()
  const project = useProject()
  const survey = useSurvey()
  const isInit = useRef(false)
  const {popup, confirm, alert} = useCommonPopup()

  useEffect(_ => {
    
    initPopupMenu()   
    
  }, [])

  useEffect(_ => {
    
    if (!tab) {
      if (!isInit.current && !!survey.surveyData && props.onChangeTab) {
        const query = queryString.parse(location.search);
        if (!!query?.participant && survey.surveyData?.status !== SURVEY_STATUS_WRITING) {
          props.onChangeTab("answer")
        } else {
          props.onChangeTab(survey.surveyData?.status === SURVEY_STATUS_WRITING ? "survey" : "answer")
        }
        isInit.current = true
      }
    } 
    
  }, [survey.surveyData])


  const onClickHome = () => {

    navigate("/dashboard")
  }

  const onClickExport = async () => {

    var result = await popup(
      <SurveyExportPopup
        projectData={project.projectData} 
        surveyData={survey.surveyData}
      />, {
        container: "project survey",
        name: "SurveySendPopup",
        style: "survey_send",
        useContainer: false,
      }
    )    
    if (result === false) return
    if ( !!result?.errors ) {
      survey.setError(result.errors, true)
      return
    } 
    if (project.projectData?.eCRF !== 1) {
      await popup(
        <SurveyLinkPopup
          projectData={project.projectData} 
          surveyData={survey.surveyData}
          link={result}
        />,
        {
          container: "project survey",
          name: "SurveyLinkPopup",
          style: "survey_send",
          useContainer: false,
        }, 
      )
    }
    await survey.getSurvey()
  }

  const onClickClose = async () => {

    var close = await popup(
      <SurveyLinkPopup
        projectData={project.projectData} 
        surveyData={survey.surveyData}
        link={survey.surveyData.link}
      />,
      {
        container: "project survey",
        name: "SurveyLinkPopup",
        style: "survey_send",
        useContainer: false,
      }, 
    )
    if (close !== true) return 

    if ( await confirm(
      `설문을 종료하면 설문에 대한 접근이 차단되며 참여자들은 설문을 더 이상 볼 수 없습니다.<br/>
      한 번 종료된 설문을 다시 진행하려면 새로운 복사본을 생성하여  설문을 시작해야 합니다.<br/><br/>
      설문을 종료하시겠습니까?`, {
        title: "설문 종료", 
        confirm: "종료",
        style: "medium"
      }) !== true) return

    const result = await api.post("closeSurvey", {
      survey: survey.surveyId,
    })
    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
      return 
    } else {
      await survey.getSurvey()
    }
  }

  const onClickSurvey = () => {

    if (props.onChangeTab) props.onChangeTab("survey")
  }

  const onClickAnswer = () => {

    if (props.onChangeTab) props.onChangeTab("answer")
  }

  const onClickMobilePriview = () => {

    if (project.projectData?.eCRF === 1) {
      window.open(`/preview/ecrf/${survey.surveyId}`)  
    } else {
      window.open(`/preview/${survey.surveyId}`)  
    }    
  }

  const onClickScaleSource = () => {

    if (!survey.surveyData) return

    popup(<SurveyScaleSourcePopup surveyId={survey.surveyId}/>,
      {
        container: "survey",
        name:"popup-survey-scale-source",
        style:"view_source",      
        hasFooter:false,
        title: "출처 보기"
      }, 
    )
  }

  const onClickDelete = async () => {

    if (await confirm("이 설문을 삭제하시겠습니까?<br/>설문을 삭제하면 모든 공동 작업자가 더 이상 해당 설문을 볼 수 없게 되며, 설문이 완전히 삭제됩니다.", {
        title: "설문 삭제", 
        confirm: "삭제",
        style: "medium"
      })
    ) {
      const result = await api.post("deleteSurvey", {
        survey: survey.surveyId,
      })
      if (result?.data?.result != "ok") {
        api.networkError(result, alert)
        return 
      } else {
        navigate(`/project/${project.projectId}`)
      }
    } 
  }

  const openNav = () => {

    $('.project_head .nav').addClass('active');
    $('.project_head .nav .dim_bg').show();
    $('body').addClass('pop');
  }

  const closeNav = () => {

    $(' .project_head .nav').removeClass('active');
    $(' .project_head .nav .dim_bg').hide();
    $('body').removeClass('pop');
  }

  const onClickProjectHome = () => {
        
    closeNav()
    if (!project.projectData?.idproject) return
    navigate(`../${project.projectId}`)
  }

  const onClickParticipant = () => {

    closeNav()

    if (!project.projectData?.idproject) return
    navigate(`../${project.projectId}/participant`)
  }

  return (
    <header>
      <div  className="project_head suvay_head">
        <div className="inwrap">
          <div className="head_profile">
            <div>
            { project.projectData?.eCRF === 1 && <i className="icon-menu" onClick={() => {openNav()}}></i> }
            <h1>
                <a onClick={onClickHome}><i className="icon-logo"></i></a>
            </h1>
            <h4> <i className="icon-arrowleft" onClick={() => navigate(`/project/${project.projectId}`)}></i> {survey.surveyData?.name}</h4>
            </div>
            <div>
            {survey.surveyData?.status === SURVEY_STATUS_WRITING && <button className="primary large survey_send_btn" onClick={onClickExport}>설문 보내기</button>}
            {survey.surveyData?.status === SURVEY_STATUS_OPEN && <button className="primary large" onClick={onClickClose}><i className="icon-ing_clock"></i>설문 진행중</button>}
            {survey.surveyData?.status === SURVEY_STATUS_CLOSED && <button className="disabled large "><i className="icon-union"></i>설문 종료됨</button>}            
            <UserName/>
            <div className="dot_menu popmenu">
                <i className="icon-dot-menu"></i>
                <ul className="menu_pop">
                  <li className="survey_del_btn" onClick={onClickDelete}>설문 삭제</li>
                </ul>
            </div>
            </div>
          </div>
        </div>
        <div className="head_info"> 
          <div className="inwrap">
            <ul>
              <li className={props.tab != "answer" ? "active" : "c_disabled"}>
                <a className={props.tab == "answer" ? "c_disabled" : ""} onClick={onClickSurvey}>
                  설문
                </a>
              </li>
              { survey.surveyData?.status !== SURVEY_STATUS_WRITING &&
                <li className={props.tab == "answer" ? "active" : "c_disabled"}>
                  <a className={props.tab != "answer" ? "c_disabled" : ""} onClick={onClickAnswer}>
                    응답
                    <span className="hit_gray">{survey.answerCount?? ""}</span>
                  </a>
                </li>
              }
            </ul>  
            <ul>
                <li>
                <button className="text_only" onClick={onClickMobilePriview}>모바일 미리보기</button>
                </li>
              <li>
                {survey.surveyData?.hasscale === 1 && <button className="text_only view_btn" onClick={onClickScaleSource}>출처보기</button>}
              </li>
            </ul>
          </div>
        </div>  
        <div className="nav">
          { project.projectData?.eCRF === 1 && 
            <nav>        
              <div><i className="icon-close" onClick={() => {closeNav()}}></i></div>
              <ul>
                <li><a onClick={onClickProjectHome}><i className="icon-home" ></i>프로젝트 홈</a></li>
                <li><a onClick={onClickParticipant}><i className="icon-team" ></i>참여자 관리</a></li>
              </ul>
            </nav>
          }
          <div className="dim_bg" onClick={() => closeNav()}></div>
        </div>      
      </div>
    </header>  
  );
}  
export default SurveyHeader;