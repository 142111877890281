import $ from 'jquery';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useApi } from "../../context/ApiContext";
import PreviewSection from './PrivewSection';
import PreviewQuestion from './PrviewQuestion';
import AnswerHeader from '../answer/AnswerHeader';

const PreviewContent = props => {

  const navigate = useNavigate()
  const location = useLocation()
  const { surveyId } = useParams()
  const api = useApi()
  const [accessToken, setAccessToken] = useState()

  const oldData = useRef()
  const [surveyData, setSurveyData] = useState()
  const [currentData, setCurrentData] = useState()
  const [error, setError] = useState()

  const [group, setGroup] = useState("A")
  const [device, setDevice] = useState("tablet")
  const [showList, setShowList] = useState(false)
  const eCRF = useRef(false)

  useEffect(_ => {

  }, [])

  useEffect(_ => {
    
  }, [props])

  useEffect(_ => {
    
    if (!!surveyId) {

      eCRF.current = location.pathname.includes("ecrf")
      showPreview(location.state?.moveaction?? "START", location.state?.group?? "A", location.state?.answer)
      setGroup(location.state?.group?? "A")

      if (!location.state?.surveyData) {
        getSurveyData()
      } else {
        setSurveyData(location.state?.surveyData)
      }
      setShowList(false)      
    } else {
      navigate("/preview")
    }

  }, [location])

  useEffect(_ => {
    
    if (showList) {
      $(`.question_list, .dim_bg`).show();
    } else {
      $(`.question_list, .dim_bg`).hide();
    }
  }, [showList])

  
  useEffect(_ => {
    
    oldData.current = {
      ...currentData
    }
    setError(false)
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })    
  }, [currentData])
 
  const getSurveyData = async () => {

    const result = await api.post("getSurvey", {
      survey: surveyId
    })
    
    if (result?.data?.result != "ok") {
      api.networkError(result, alert)      
    } else {   
      setSurveyData(result.data.data.survey)      
    }
  }

  const showPreview = async (moveaction, group, answer) => {
    
    const result = await api.post("showPreview", {
      survey : surveyId,
      moveaction : moveaction,
      currentsectionorderno : location.state?.currentsectionorderno?? "", 
      currentquestionorderno : location.state?.currentquestionorderno?? "",
      participantgroup: group,
      answercontent: answer,
    })
    
    if (result?.data?.result != "ok") {     
      setError(true)
    } else {      
      setCurrentData(result.data.data.survey)
    }
  }


  const onClickList = () => {
    setShowList(!showList)
  }

  const onSelectGroup = (value) => {

    navigate(`${location.search}`, {
      state: { 
        group: value
      },
      replace: true
    })
  }

  const onSelectDevice = (value) => {

    setDevice(value)
  }

  const onClickPrevPage = () => {

    if ((currentData?.currentpage?? 0) <= 1) return

    navigate(`${location.search}`, {
      state: { 
        moveaction: "PREV",
        currentsectionorderno : currentData?.section?.orderno, 
        currentquestionorderno : currentData?.question?.orderno,
        group: group,
        surveyData: surveyData,
      },
      replace: true
    })
  }

  const onClickNextPage = () => {

    if ((currentData?.currentpage?? 0) >= (currentData?.totalpage?? 0)) return

    navigate(`${location.search}`, {
      state: { 
        moveaction: "NEXT",
        currentsectionorderno : currentData?.section?.orderno, 
        currentquestionorderno : currentData?.question?.orderno,
        group: group,
        surveyData: surveyData,
      },
      replace: true
    })
  }

  const onClickDirectPage = (section, question) => {

    if (section?.participantgroup !== "ALL" && section?.participantgroup !== group) return

    navigate(`${location.search}`, {
      state: { 
        moveaction: "DIRECT",
        currentsectionorderno : section?.orderno?? 0, 
        currentquestionorderno : question?.orderno?? 0,
        group: group,
        surveyData: surveyData,
      },
      replace: true
    })
  }

  const onClickPrev = () => {
    navigate(`${location.search}`, {
      state: { 
        moveaction: "PREV",
        currentsectionorderno : currentData?.section?.orderno, 
        currentquestionorderno : currentData?.question?.orderno,
        group: group,
        surveyData: surveyData,
      },
      replace: true
    })
  }

  const onClickNext = (answer) => {

    navigate(`${location.search}`, {
      state: { 
        moveaction: "NEXT",
        currentsectionorderno : currentData?.section?.orderno, 
        currentquestionorderno : currentData?.question?.orderno,
        answer: answer,
        group: group,
        surveyData: surveyData,
      },
      replace: true
    })
  }

  const PreviewComplete = () => {

    const onClickClose = () => {
  
      window.close()
    }
  
    return ( 
      <div className="inwrap start">
        <div className='questionnair_body'>
          <div></div>
          <h3>
          👍👏🥰<span  className='red_c'>❤</span><br/>
          설문을 완료했습니다.
          </h3>
          <h4 className=''>참여해주셔서 진심으로 감사드립니다.</h4>
          </div>
          {/* <div className="but_wrap">
            <button type="button" className="primary large full" onClick={onClickClose}>종료</button>
          </div> */}
        </div> 
    )
  }

  const PreviewError = () => {

    return ( 
      <div className="inwrap start">
        <div className='questionnair_body'>
          <div></div>
          <h3>
          😵 <br/>
          설문지를 표시할 수 없습니다.
          </h3>
          <h4>이전 화면으로 돌아가 다시 시도해보세요. </h4>
          <h4>계속해서 문제가 발생한다면, contact@atflow.kr로 이메일을 보내주시면 신속하게 도움을 드리겠습니다.</h4>
          <h4>불편을 끼쳐드려 죄송합니다.</h4>
          </div>
          <div className="but_wrap">
            <button type="button" className="secondary gray large full" onClick={onClickPrev}>이전</button>
        </div>
      </div>
    )
  }

  const SurveyList = () => {
    return (
      <>
        <div className="dim_bg" onClick={() => {setShowList(false)}}></div>
        <div className="question_list">
          <h4>목차</h4>
          <ul>
            {
              surveyData?.sections?.map((section, index1) => 
                <>
                  <li key={`section-${index1}`}
                    className={`${currentData?.section?.orderno === section.orderno && currentData?.question?.orderno === 0 ? "active" : ""}
                    ${section.participantgroup !== "ALL" && section.participantgroup !== group ? "c_disabled" : ""}`}
                    onClick={() => onClickDirectPage(section)}
                    >
                    <h5>{section.orderno}. {section.name}</h5>
                    {section.participantgroup !== "ALL" && <span>{section.groupNameFull} 만</span>}
                  </li>
                  {
                    section.questions?.map((question, index2) => 
                    <li key={`question-${index2}`}
                      className={`${currentData?.section?.orderno === section.orderno && currentData?.question?.orderno === question.orderno ? "active" : ""}
                      ${["ALL", group].includes(section.participantgroup) ? "" : "c_disabled"}`}
                      onClick={() => onClickDirectPage(section, question)}
                      >
                      <p>{section.orderno}-{question.orderno}. {question.content}</p>
                    </li>
                    )
                  }
                </>
              )
            }
          </ul>
        </div>
      </>
    )
  }

  return ( 
    <div className={`question_preview ${device === "mobile" ? "mobile_view" : ""}`}>         
      <div className="preview_header">
        <ul className="tab">
          <li className={`${device === "mobile" ? "active" : ""}`} onClick={() => onSelectDevice("mobile")}>모바일</li>
          <li className={`${device !== "mobile" ? "active" : ""}`} onClick={() => onSelectDevice("tablet")}>태블릿</li>
        </ul>
        { currentData?.completed !== 1 && 
          <div>
            <i className={`icon-left ${(currentData?.currentpage?? 0) <= 1 ? "c_disabled" : ""}`} onClick={onClickPrevPage}></i>
            <span onClick={onClickList}>{currentData?.currentpage?? "-"}/{currentData?.totalpage?? "-"}</span>
            <i className={`icon-right ${(currentData?.currentpage?? 0) >= currentData?.totalpage ? "c_disabled" : ""}`} onClick={onClickNextPage}></i>
          </div>
        }
        <ul className="tab">
        { eCRF.current === true && 
          <>
            <li className={`${group === "A" ? "active" : ""}`} onClick={() => onSelectGroup("A")}>그룹 A (시험군)</li>
            <li className={`${group === "B" ? "active" : ""}`} onClick={() => onSelectGroup("B")}>그룹 B (대조군)</li>
          </>
        }
        </ul>      
      </div>      
      <SurveyList/>
      <div className="body questionnair">
        <AnswerHeader currentData = {currentData}/>
        <div className="container">
          { error === true ? (
              <PreviewError/>
            ) :  !!currentData && currentData.completed === 1 ? (
              <PreviewComplete/>
            ) : !!currentData && !currentData.question.items ? (
              <PreviewSection 
                currentData = {currentData}
                accessToken = {accessToken}
                onPrev = {onClickPrev}
                onNext = {onClickNext}
              />
            ) : !!currentData && !!currentData.question.items ? (
              <PreviewQuestion 
                currentData = {currentData}
                accessToken = {accessToken}
                onPrev = {onClickPrev}
                onNext = {onClickNext}
              />
            ) : (
              null
            )           
          }
        </div>
      </div>            
    </div>
    );
  }
  
  export default PreviewContent;